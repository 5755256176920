.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.custom-header {
  min-width: 400px;
  flex: 1;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: center;
  text-overflow: ellipsis;
}

.table th:last-child,
.table td:last-child {
  overflow: visible;
  text-overflow: unset;
  white-space: wrap;
  min-width: 200px;
}

.table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

.filter-dropdown {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  top: 100%;
  z-index: 100;
}

.crossed-out {
  text-decoration: line-through;
  color: #aaa;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.pageInfo,
.gotoPage {
  margin: 0 1rem;
}

.inputPageNumber {
  width: 50px;
  margin-left: 0.5rem;
}

.pageSizeSelect {
  margin-left: 1rem;
}
