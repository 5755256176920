.lateral-window {
  position: fixed;
  top: 0;
  right: -100%;
  width: 30%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: right 1s ease-out, box-shadow 1s ease-out;
  will-change: right, box-shadow;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.custom-close-btn {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.915);
  color: white;
  border-radius: 20px;
}

.lateral-window-content {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.lateral-window-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.lateral-window.open .lateral-window-content {
  opacity: 1;
}

.lateral-window.open,
.lateral-window.open.overlay {
  right: 0;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  display: block;
  opacity: 1;
}

.lateral-window-chip {
  background: none;
  border: none;
  position: absolute;
  top: 290px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  filter: contrast(1.2);
}

.lateral-window-chip section {
  background-color: #4d4d4d;
  opacity: 0.95;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: opacity 0.3s ease-out;
}

.overlay.open {
  display: block;
  opacity: 1;
}

.slider-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.dummy-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-header {
  min-height: 50px;
}
