.dialogContent {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  overflow: hidden;
}

.img-slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-slide {
  width: 100%;
  height: 100%;
}

.slider-img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 8px;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-slide {
  width: 100%;
  height: 100%;
}
